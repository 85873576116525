import { PageDetailsInterface } from 'constants/pageMetaTags'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC } from 'react'
// import { siteUrl } from 'services/config'

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    strategy?: string
  }
}
const HeadMetaData: FC<PageDetailsInterface> = ({
  meta,
  cssLinks,
  isNoFollow,
}) => {
  const { title, description, type, image } = meta
  const router = useRouter()
  const hasTrailingSlash = router.asPath.endsWith('/')
  // const path = router.query.path ? `/${router.query.path}` : router.pathname
  const path = hasTrailingSlash ? router.asPath : router.pathname
  const siteUrl = `${process.env.NEXT_PUBLIC_BASE_URL || ''}${path || ''}`

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, user-scalable=0"
      />
      <link href="http://gmpg.org/xfn/11" />
      <title>{title || 'Webclues Infotech'}</title>
      <meta name="dc.title" content={title || ''} />
      <meta name="dc.description" content={description || ''} />
      <meta name="dc.relation" content={path || ''} />
      <meta name="dc.source" content={siteUrl} />
      <meta name="dc.language" content="en_US" />
      <meta name="description" content={description || ''} />
      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1,max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1,max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href={siteUrl} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:site_name" content="WebcluesInfotech" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={type || ''} />
      <meta property="og:title" content={title || ''} />
      <meta property="og:description" content={description || ''} />
      {image && (
        <>
          <meta property="og:image" content={image} />
          <meta property="og:image:secure_url" content={image} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="675" />
        </>
      )}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@Webcluesinfo" />
      <meta name="twitter:title" content={title || ''} />
      <meta name="twitter:description" content={description || ''} />
      <meta name="twitter:image" content={image || ''} />
      {isNoFollow && <meta name="robots" content="noindex,nofollow" />}
      <meta
        name="google-site-verification"
        content="wQNj6hFfMzH4tfESeu8DNIiJrRfpBaLr3Ln_GTzyrHg"
      />
      {cssLinks && cssLinks.length > 0
        ? cssLinks.map((cssLink: string, i) => (
            <link key={`_pg_style_${i}`} rel="stylesheet" href={cssLink} />
          ))
        : ''}
    </Head>
  )
}

export default HeadMetaData
