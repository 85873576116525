import { ReactNode } from 'react'
import { CDN_URL } from 'services/config'

// const siteUrl = process.env.NEXT_PUBLIC_BASE_URL || ''

export interface PageDetailsInterface {
  meta: MetaInterface
  cssLinks?: string[]
  isNoFollow?: boolean
}

interface MetaInterface {
  title: string
  description: string
  type: string
  image: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IntrinsicAttributes = any

interface PagesDetailsInterface {
  [x: string]: IntrinsicAttributes &
    PageDetailsInterface & { children?: ReactNode }
  home: PageDetailsInterface
  contact: PageDetailsInterface
  services: PageDetailsInterface
  technology: PageDetailsInterface
  subtechnology: PageDetailsInterface
  webDevelopment: PageDetailsInterface
  teamExtension: PageDetailsInterface
  mobileDevelopment: PageDetailsInterface
  productEngineering: PageDetailsInterface
  qaServices: PageDetailsInterface
  work: PageDetailsInterface
  cloudandDevops: PageDetailsInterface
  uiuxDesign: PageDetailsInterface
  aboutUs: PageDetailsInterface
  blogListing: PageDetailsInterface
  careersListing: PageDetailsInterface
  ourTeam: PageDetailsInterface
  eventsGalleryPage: PageDetailsInterface
  getQuote: PageDetailsInterface
  'react-js-development-services': PageDetailsInterface
  'blockchain-development-services': PageDetailsInterface
  'ai-ml-development-services': PageDetailsInterface
  'chatbots-development': PageDetailsInterface
  'iot-app-development-company': PageDetailsInterface
  'data-analytics-services': PageDetailsInterface
  'magento-development-services': PageDetailsInterface
  'shopify-development-services': PageDetailsInterface
  'opencart-development-services': PageDetailsInterface
  'prestashop-development-services': PageDetailsInterface
  'woocommerce-development-services': PageDetailsInterface
  'drupal-development-services': PageDetailsInterface
  'nodejs-development-services': PageDetailsInterface
  'rust-development-services': PageDetailsInterface
  'python-development-company': PageDetailsInterface
  'java-development-services': PageDetailsInterface
  'php-development-services': PageDetailsInterface
  'goLang-development-services': PageDetailsInterface
  'angularjs-development-services': PageDetailsInterface
  'vuejs-development-company': PageDetailsInterface
  'nextjs-development-company': PageDetailsInterface
  'typescript-development-services': PageDetailsInterface
  'html5-development': PageDetailsInterface
  'react-native-app-development': PageDetailsInterface
  'flutter-app-development': PageDetailsInterface
  'swift-app-development': PageDetailsInterface
  'kotlin-app-development': PageDetailsInterface
  'ios-app-development': PageDetailsInterface
  'android-app-development-services': PageDetailsInterface
  'ionic-app-development': PageDetailsInterface
  'xamarin-development': PageDetailsInterface
  'hire-mern-stack-developers': PageDetailsInterface
  'hire-mean-stack-developers': PageDetailsInterface
  'django-development': PageDetailsInterface
  'flask-web-development': PageDetailsInterface
  'laravel-development-services': PageDetailsInterface
  'codeigniter-development-services': PageDetailsInterface
  'wordpress-development-services': PageDetailsInterface
  'spring-development': PageDetailsInterface
  'hibernate-development': PageDetailsInterface
  'aws-development': PageDetailsInterface
  'google-cloud-services': PageDetailsInterface
  'ibm-watson-developer': PageDetailsInterface
  'rasa-chatbot': PageDetailsInterface
  thankYou: PageDetailsInterface
  thankYou1: PageDetailsInterface
  AspDotnetMvc: PageDetailsInterface
  AzureDevelopment: PageDetailsInterface
  DotNetCoreDevelopment: PageDetailsInterface
  'dot-net-development-company': PageDetailsInterface
  eLearningLikeCoursera: PageDetailsInterface
  foodDeliveryLikeUberEats: PageDetailsInterface
  developAppLikeAirbnb: PageDetailsInterface
  costToMakeaVacationRentalApp: PageDetailsInterface
  createYourOwnElearningPlatform: PageDetailsInterface
  buildYourOwnElearningPlatform: PageDetailsInterface
  sportsBettingAppLikeDream: PageDetailsInterface
  sportsBettingAppBet: PageDetailsInterface
  elearningAppLikeUdemyLynda: PageDetailsInterface
  groceryDeliveryAppLikeInstacart: PageDetailsInterface
  socialNetworkingAppLikeInstagram: PageDetailsInterface
  ecommerceWebsiteLikeAmazonEbay: PageDetailsInterface
  taxiAppLikeUber: PageDetailsInterface
  foodDeliveryAppLikeZomato: PageDetailsInterface
  healthcareAppLikePracto: PageDetailsInterface
  healthcareAppLikeZocdoc: PageDetailsInterface
  costToCreateaHealthCareApp: PageDetailsInterface
  costToMakeaMedicalApp: PageDetailsInterface
  videoStreamingAppLikeNetflix: PageDetailsInterface
  musicVideoAppLikeTiktok: PageDetailsInterface
  jewelleryWebsiteAppDevelopment: PageDetailsInterface
  termsConditions: PageDetailsInterface
  privacyPolicyPage: PageDetailsInterface
  elearningAppsLikeByjus: PageDetailsInterface
  videoSharingAppsLikeYoutube: PageDetailsInterface
  appsLikeTinder: PageDetailsInterface
  developChatApps: PageDetailsInterface
  ecommerce: PageDetailsInterface
  azure: PageDetailsInterface
  'dotnet-core': PageDetailsInterface
  'dotnet-mvc': PageDetailsInterface
  ecommerceDevelopmentCompany: PageDetailsInterface
  penetrationTestingService: PageDetailsInterface
}

const PAGE_CONSTANT_TEXTS: PagesDetailsInterface = {
  home: {
    meta: {
      title:
        'Top Mobile App and Software Development Company - WebClues Infotech',
      description:
        'WebClues Infotech is a new-gen web & mobile app development company offering new possibilities & opportunities for businesses in every industry to grow with smart digital solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/home-page.jpg`,
    },
  },
  services: {
    meta: {
      title: 'Mobile App Development Services | Web Development Services',
      description:
        'Our web development services & mobile app development services leverage latest technologies to design & develop smart digital solutions that run seamlessly on all digital devices.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/services.jpg`,
    },
  },
  technology: {
    meta: {
      title: 'Web and Mobile App Development - Technology stack',
      description:
        'Build smart, interactive, and intuitive web and mobile app solutions using state-of-the-art technology stack. We use the latest Technology Stack For Android & iOS Mobile App Development.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/technology.jpg`,
    },
  },
  subtechnology: {
    meta: {
      title: 'Latest IT Tech Solution Development Company - WebClues Infotech',
      description:
        'WebClues Infotech believes to take advantage of new tech that is Internet of things dev, iBeacon App dev, AR Apps dev, Apple Watch App dev to create disruptive solutions.',
      type: 'website',
      image: ``,
    },
  },
  webDevelopment: {
    meta: {
      title: 'Web Development Company | Web Design and Development Services',
      description:
        'We are a new generation full-service website development company. Specializing in web development services. Developed over 600 websites with technologies like WooCommerce, WordPress.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/web-development.jpg`,
    },
  },
  teamExtension: {
    meta: {
      title: 'Team Extension Services | Hire Dedicated Developers',
      description:
        'Our team extension services can provide access to highly skilled developers for the best results. Hire Dedicated Developers to augment your existing software development team.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/team-extension.jpg`,
    },
  },
  mobileDevelopment: {
    meta: {
      title: 'Mobile App Development Services | Mobile App Development',
      description:
        'We are counted among trusted mobile app development company. Our dedicated mobile app developers are experts in a full range of mobile app design and development services.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobility-solutions.jpg`,
    },
  },
  productEngineering: {
    meta: {
      title:
        'Software Product Engineering Services | Product Engineering Company',
      description:
        'WebClues can simplify the art of building complex apps with high-tech Product Engineering Services & Solutions. We build future-proof software products with innovative product engineering services.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/product-engineering.jpg`,
    },
  },
  qaServices: {
    meta: {
      title: 'Software Testing Services | Quality Assurance Services',
      description:
        'WebClues provides value-driven software testing and QA services within optimal time and budget. Our Best QA Services & Software Testing Solutions will significantly mitigate the technical and business risks.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/quality-analyst.jpg`,
    },
  },
  contact: {
    meta: {
      title: 'Contact Us - WebClues Infotech',
      description:
        'Contact WebClues by filling out the form or meet us by visiting our offices in Ahmedabad, Rajkot, and Chennai. Find solutions to your problem and get help from our tech support team.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/contact-us.jpg`,
    },
  },
  work: {
    meta: {
      title: 'Portfolio | Web and Mobile App Development Company',
      description:
        'We deliver solutions that our clients love. Check out our portfolio section which is fueled by a passion for innovation and creation.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/portfolio.jpg`,
    },
  },
  cloudandDevops: {
    meta: {
      title: 'DevOps Consulting Services | DevOps Service Providers',
      description:
        'WebClues is a next-gen DevOps Consulting Services and Solutions Company. Our DevOps as a service include software coding and development, infrastructure management, & CI/CD toolchains.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/clouds-and-devops.jpg`,
    },
  },
  uiuxDesign: {
    meta: {
      title: 'UI/UX Development Company | UI/UX Design Services',
      description:
        'Level up your business with futuristic design solutions with top UI/UX Development Company. Our UI/UX Design Services are designed strategically to capture the attention of users.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ux-ui-design.jpg`,
    },
  },
  aboutUs: {
    meta: {
      title: 'About Us - Web and Mobile App Development',
      description:
        'Web and mobile app development company, we build websites, web apps, mobile apps, we experiment with new technologies (Blockchain, AI/ML, IoT)',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/about-us.jpg`,
    },
  },
  blogListing: {
    meta: {
      title: 'Blog - WebClues Infotech',
      description:
        'Featuring the latest and trending blogs related to web and mobile app development technology and services. We share insights and knowledge with everyone via our blog.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/blog.jpg`,
    },
  },
  careersListing: {
    meta: {
      title: 'Join Our Team | WebClues Infotech',
      description: 'Work with our creative and talented minds.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/current-opnings.jpg`,
    },
  },
  ourTeam: {
    meta: {
      title: 'Team - WebClues Infotech',
      description:
        'Meet our team who shares common goals, values, and are dedicatedly committed  towards their work',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/team.jpg`,
    },
  },
  eventsGalleryPage: {
    meta: {
      title: 'Events & Gallery - WebClues Infotech',
      description:
        'Take a glimpse at our celebrations, harmonious & nurturing work environment, and friendly culture.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/events.jpg`,
    },
  },
  getQuote: {
    meta: {
      title: 'Get a Quote - WebClues Infotech',
      description:
        'Let us know about your project and get a free quote for software development solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/get-a-quote.jpg`,
    },
  },
  'react-js-development-services': {
    meta: {
      title: 'ReactJS Development Company | ReactJS Consultation Services',
      description:
        'WebClues is a ReactJS development company that offers ReactJS development services to clients around the world. Our dedicated ReactJS developers can easily develop fast & cost-effective ReactJS-based solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-react-js.jpg`,
    },
  },
  'blockchain-development-services': {
    meta: {
      title:
        'Blockchain App Development Company | Blockchain Consulting Services',
      description:
        'WebClues is a top blockchain development company in India & USA. Our blockchain development services can cater to all business needs and requirements helping us serve clients across the globe.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-blockchain.jpg`,
    },
  },
  'ai-ml-development-services': {
    meta: {
      title:
        'AI/ML Development & Consulting Services Company - WebClues Infotech',
      description:
        'Automate your business processes with our AI/ML Development Services. We as a leading AI/ML Development Company can help you build AI-enabled products for your business.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ai-ml-solutions-development.jpg`,
    },
  },
  'chatbots-development': {
    meta: {
      title: 'Chatbot App Development Company | Chatbot Development Services',
      description:
        'Hire a Chatbot App Development Company like WebClues for Customer-Centric Chatbot app development services. We can easily build an Automated Response Mechanism for any type of industry.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-chatbot-app.jpg`,
    },
  },
  'iot-app-development-company': {
    meta: {
      title: 'IoT Development Company | IoT Consulting Services',
      description:
        'Harness the true potential of IoT technology with an experienced IoT App Development Company (WebClues). We provide bespoke IoT Development Services for any industry while catering to their specific needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-iot.jpg`,
    },
  },
  'data-analytics-services': {
    meta: {
      title: 'Data Analytics Company | Data Analytics Services',
      description:
        'WebClues is a top Data Analytics Company that helps businesses with big data solutions. Hire Data Scientists with tangible experience & expertise for global standard services.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-data-analytics.jpg`,
    },
  },
  'magento-development-services': {
    meta: {
      title: 'Magento Development Company | Magento Web Design and Development',
      description:
        'WebClues is a trusted Magento Development Company. Our Magento Development Services provide high-quality solutions with the required functionality, features, themes, & designs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/magento.jpg`,
    },
  },
  'shopify-development-services': {
    meta: {
      title: 'Shopify Development Company | Shopify Web Design Agency',
      description:
        'Looking for Shopify development service? Our Shopify development agency can power up your website with Shopify Integrations Solutions. We provide state-of-the-art Shopify Web Development Services.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/shopify.jpg`,
    },
  },
  'opencart-development-services': {
    meta: {
      title:
        'OpenCart Development Company | OpenCart Web Design and Development',
      description:
        'WebClues works with modern technologies to develop enticing OpenCart solutions. Our OpenCart development services can revolutionize your eCommerce business. Consult us for smart web solutions!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/opencart.jpg`,
    },
  },
  'prestashop-development-services': {
    meta: {
      title:
        'Prestashop Development Company | Prestashop Web Development Services',
      description:
        'We are a top Prestashop Development Company offering custom PrestaShop development services including customization, Plugin, API Integrations. Hire PrestaShop Developer in India & USA.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/prestashop.jpg`,
    },
  },
  'woocommerce-development-services': {
    meta: {
      title:
        'Woocommerce Development Company | Custom WooCommerce Development Services',
      description:
        'Work with top WooCommerce Development Company. Hire WooCommerce developers who use ingenious technologies for effective WooCommerce Development according to your business needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/woocommerce.jpg`,
    },
  },
  'drupal-development-services': {
    meta: {
      title: 'Drupal Development Company | Drupal Web Development Services',
      description:
        'We are a top Drupal Development agency specializing in Drupal development services. Our Drupal developers excel at every bit of development from ideation to deployment & maintenance.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/drupal.jpg`,
    },
  },
  'nodejs-development-services': {
    meta: {
      title: 'Node.JS Development Services | Node.JS Development Company',
      description:
        'We are a top Node.JS application Development Company. Hire NodeJS Developers that provide the best .Node.JS Development Solutions that align with your business goals.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-nodejs.jpg`,
    },
  },
  'rust-development-services': {
    meta: {
      title: 'Rust Development Services | Rust Development Company',
      description:
        'We are a top Node.JS application Development Company. Hire NodeJS Developers that provide the best .Node.JS Development Solutions that align with your business goals.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-rust.jpg`,
    },
  },
  'python-development-company': {
    meta: {
      title: 'Python Development Company | Python Development Services',
      description:
        'WebClues is a top Python development company offering advanced and effective Python Development Services according to the business needs and customer preferences at affordable rates.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-python.jpg`,
    },
  },
  'java-development-services': {
    meta: {
      title: 'Java Application Development Company | Java Development Services',
      description:
        'Webclues is an expert JAVA App development company. We develop result-oriented and feature-rich apps with the best Java software development services. WebClues can provide you with expert JAVA developers, testers, & designers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-Java.jpg`,
    },
  },
  'php-development-services': {
    meta: {
      title: 'PHP Development Services | PHP Development Company',
      description:
        'WebClues is a PHP development company that offers various PHP development services. Hire PHP Developers and get feature-rich and scalable PHP solutions in record time.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-php.jpg`,
    },
  },
  'goLang-development-services': {
    meta: {
      title: 'GoLang Development Services | GoLang Development Company',
      description:
        'WebClues is a leading GoLang development company that leverages the latest technologies to build custom applications that are scalable, secure, and optimized for performance.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-golang.jpg`,
    },
  },
  'angularjs-development-services': {
    meta: {
      title: 'AngularJS Development Company | AngularJS Development Services',
      description:
        'WebClues is a top AngularJS development company. Hire Angularjs developers from us and build advanced AngularJS solutions for enterprises with advanced development needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-angular-js.jpg`,
    },
  },
  'vuejs-development-company': {
    meta: {
      title: 'VueJS Development Company | VueJS Development Services',
      description:
        'Collaborate with WebClues (Vue.JS Development Company) for developing VueJS-based custom projects. We offer high-performing Vue.JS Development services to clients globally.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-vue-js.jpg`,
    },
  },
  'nextjs-development-company': {
    meta: {
      title: 'NextJS Development Company | NextJS Development Services',
      description:
        'Get Next.JS applications developed by experts at WebClues (Next.js Development Company). Our Next.JS Development Services build powerful game-changing apps with latest functionalities.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-next-js.jpg`,
    },
  },
  'typescript-development-services': {
    meta: {
      title: 'TypeScript Web Development Company | Hire TypeScript Developer',
      description:
        'WebClues is a top TypeScript Development Company that builds scalable and interactive apps with unparalleled UI. Get quality TypeScript Development services for any industry vertical.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-typescript.jpg`,
    },
  },
  'html5-development': {
    meta: {
      title: 'HTML5 Web Development Company | HTML5 Development Services',
      description:
        "Hire HTML5 developer from WebClues. We are a top HTML5 Development Company that develops user-friendly websites/apps & transformative design solutions exactly as per the client's vision.",
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/frontend-technologies-html5.jpg`,
    },
  },
  'react-native-app-development': {
    meta: {
      title:
        'React Native Mobile App Development Company | React Native Consulting Services',
      description:
        'WebClues is an expert in building unparalleled user experiences for iOS & Android users with React Native. Hire our React Native Developers for all your development needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-react-native.jpg`,
    },
  },
  'flutter-app-development': {
    meta: {
      title: 'Flutter Development Company | Flutter App Development Services',
      description:
        'WebClues is a reliable service provider that offers end-to-end Flutter App Development Services. We leverage Flutter’s potential to develop next-gen apps for every business.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-flutter.jpg`,
    },
  },
  'swift-app-development': {
    meta: {
      title: 'Swift App Development Company | Swift App Development Services',
      description:
        'WebClues has an expert team of Swift developers who can cater to all Swift Development Services and needs. We can build any type of iOS app for your customers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-swift.jpg`,
    },
  },
  'kotlin-app-development': {
    meta: {
      title: 'Kotlin App Development Services | Kotlin App Development Company',
      description:
        'Choose WebClues for Kotlin App Development Services. Our Kotlin consultants can help you choose the right approach for developing your Kotlin-based solution.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-kotlin.jpg`,
    },
  },
  'ios-app-development': {
    meta: {
      title: 'iOS App Development Company | iOS App Development Services',
      description:
        'Leverage our rich expertise in iOS app development for building simple or complex iOS-based apps. Besides development, we help businesses with iOS consultation, deciding which tech stack is the best for their solution.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-ios.jpg`,
    },
  },
  'android-app-development-services': {
    meta: {
      title:
        'Android App Development Company | Android App Development Services',
      description:
        'WebClues is an expert at building scalable & secure Android apps matching your brand image. Our custom Android App development services develop state-of-the-art android apps for all android devices.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-android.jpg`,
    },
  },
  'ionic-app-development': {
    meta: {
      title: 'Ionic App Development Company | Hire Ionic Developer',
      description:
        'WebClues is a top Ionic App development company. Our Ionic App Development Services provide unmatched performance and agility to businesses. Hire Ionic developer & start building your app.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-Ionic.jpg`,
    },
  },
  'xamarin-development': {
    meta: {
      title: 'Xamarin Development Company | Xamarin App Development Services',
      description:
        'As a top Xamarin app development company, we develop highly advanced & market-fit apps using our custom Xamarin development services. Contact us to Hire Xamarin Developers.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-xamarin.jpg`,
    },
  },
  'hire-mern-stack-developers': {
    meta: {
      title: 'MERN Stack Development Services | MERN Stack Development Company',
      description:
        'WebClues is a reliable MERN Stack development company. Our MERN Stack developers are best positioned to offer custom MERN Stack solutions meant for your business & target audience.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-mern.jpg`,
    },
  },
  'hire-mean-stack-developers': {
    meta: {
      title: 'MEAN Stack Development Company | MEAN Stack Development Services',
      description:
        'WebClues builds highly efficient & fast-performing solutions using custom MEAN Stack Development Services. Startups & companies can Hire Dedicated MEAN Stack developers for quality solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-mean.jpg`,
    },
  },
  'django-development': {
    meta: {
      title: 'Django Development Company | Custom Django Development Services',
      description:
        'WebClues is a  top Django Development Company. We help businesses in developing pragmatic design & web interfaces. Hire Django Developers that are qualified & experienced.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-django.jpg`,
    },
  },
  'flask-web-development': {
    meta: {
      title: 'Flask Web Development | Python based Flask Web Development',
      description:
        ' WebClues builds performant & business-oriented web applications with Flask. We as a known Python Flask Web Development Company deliver solutions as per your core requirements.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-flask.jpg`,
    },
  },
  'laravel-development-services': {
    meta: {
      title: 'Laravel Development Company | Laravel Development Services',
      description:
        'Foster your business growth using our top-notch Laravel Development Services. We as a Laravel Development company build aesthetically appealing & high-performance Laravel web applications.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-laravel.jpg`,
    },
  },
  'codeigniter-development-services': {
    meta: {
      title:
        'CodeIgniter Web Development Company | CodeIgniter Development Services',
      description:
        'Hire CodeIgniter Developers from WebClues who develop performance-ready web applications. Our CodeIgniter development services can build simple to complex web apps with ease.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-codeIgniter.jpg`,
    },
  },
  'wordpress-development-services': {
    meta: {
      title:
        'WordPress Development Agency | WordPress Website Designing Company',
      description:
        'As a WordPress Development Company, we build strategic WordPress solutions using our effective WordPress development services that lead to higher engagement & growth.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-wordpress.jpg`,
    },
  },
  'spring-development': {
    meta: {
      title:
        'Spring Web Application Development Company | Spring Framework Development Services',
      description:
        'WebClues is a Spring development company that offers Spring development services to create solutions for growing Businesses. We leverage the speed & simplicity of Spring framework to develop bespoke solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-spring.jpg`,
    },
  },
  'hibernate-development': {
    meta: {
      title: 'Hibernate Development Company | Hibernate Development Services',
      description:
        'Hire Hibernate Developer from us. We provide bespoke Hibernate Development Services. Our hibernate framework developers build high-performance solutions for every business.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-hibernate.jpg`,
    },
  },
  'aws-development': {
    meta: {
      title: 'Amazon Web Services | AWS Professional Services',
      description:
        'WebClues provides AWS cloud computing, amazon web services at affordable rates. We can cost-efficiently recommend AWS required for your business.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-aws.jpg`,
    },
  },
  'google-cloud-services': {
    meta: {
      title: 'Google Cloud Service Providers - WebClues Infotech',
      description:
        'WebClues is one stop solution to Google Cloud Services. Our Google Cloud Computing Services are secure & scalable, matching your specific & general needs. Hire Certified Google Cloud Developers!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-google-cloud.jpg`,
    },
  },
  'ibm-watson-developer': {
    meta: {
      title: 'IBM Watson Services | IBM Watson Consulting Service',
      description:
        'Hiring IBM Watson developer from WebClues is quite affordable. We provide IBM Watson Services to start deploying AI concepts into your business for fast and secure processes.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-ibm-watson.jpg`,
    },
  },
  'rasa-chatbot': {
    meta: {
      title: 'Hibernate Development Company | Hibernate Development Services',
      description:
        'Hire Hibernate Developer from us. We provide bespoke Hibernate Development Services. Our hibernate framework developers build high-performance solutions for every business.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-chatbot-app.jpg`,
    },
  },
  thankYou: {
    meta: {
      title: 'Thank You - WebClues Infotech',
      description:
        'Thank you for reaching out to us for your web and mobile app development needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/thank-you.jpg`,
    },
  },
  thankYou1: {
    meta: {
      title: 'Thank You Contact | WebClues Infotech',
      description:
        'Thank you for reaching out to us for your web and mobile app development needs.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/thank-you.jpg`,
    },
  },
  AspDotnetMvc: {
    meta: {
      title: 'Hire Microsoft ASP.NET MVC Developers | ASP.NET MVC Development',
      description:
        'WebClues Infotech offers top-notch Microsoft ASP.NET MVC Development services. Our ASP.NET MVC developers provide you with robust and reliable ASP.NET MVC development solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-asp-dot-net.jpg`,
    },
    cssLinks: ['/assets/css/dotnet_mvc_blog_page.css'],
  },
  AzureDevelopment: {
    meta: {
      title: 'Microsoft Azure Development services | Hire Azure Developers',
      description:
        'WebClues is known for its Microsoft Azure development services because we have a team of highly skilled Azure developers that develop simplistic Azure architectures for your business solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-azure.jpg`,
    },
    cssLinks: ['/assets/css/microsoft_azure_blog_page_v3.css'],
  },
  DotNetCoreDevelopment: {
    meta: {
      title: '.NET Core Development Services | Hire .Net Core developers',
      description:
        'WebClues is a top Microsoft .NET Core Development company that provides full-cycle ASP.Net Core Development services.  Hire our .NET Core developers for robust and stable .Net core solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-dotnet-core.jpg`,
    },
    cssLinks: ['/assets/css/dotnet_core_blog_page_v2.css'],
  },
  'dot-net-development-company': {
    meta: {
      title: '.NET Development Company | .NET Development Services',
      description:
        'We are an experienced .Net Development Company providing smart & secure .Net-based solutions. Our .NET Development Services follow agile development methodologies empowering businesses with result-oriented solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-dotnet.jpg`,
    },
  },
  eLearningLikeCoursera: {
    meta: {
      title: 'How to build an Elearning app or website like Coursera',
      description:
        'WebClues provides a detailed guide on how to build an app/website like Coursera, How much it cost & what are essential features required. Get consultation!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/app-like-coursera.jpg`,
    },
    cssLinks: ['/assets/css/coursera_page.css'],
  },
  buildYourOwnElearningPlatform: {
    meta: {
      title: 'How to build an Elearning app or website like Coursera',
      description:
        'WebClues provides a detailed guide on how to build an app/website like Coursera, How much it cost & what are essential features required. Get consultation!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/e-learning-app-like-coursera.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/coursera_page.css'],
  },
  foodDeliveryLikeUberEats: {
    meta: {
      title: 'Cost to create a food delivery application similar to UberEats',
      description:
        'How to build food delivery app like UberEats; consult experts at WebClues. Get complete insight on how to build & how much it cost to build app like UberEats.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/food-delivery-app-like-uber-eats.jpg`,
    },
    cssLinks: ['/assets/css/uber_eats_page.css'],
  },
  developAppLikeAirbnb: {
    meta: {
      title: 'How Much Does it Cost to Develop an App like Airbnb?',
      description:
        'Looking to build an app like Airbnb for Android & iOS platform? Get insights into how to make an app like Airbnb & how much it will cost with WebClues.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/how-much-does-It-cost-to-make-an-app-like-airbnb.jpg`,
    },
    cssLinks: ['/assets/css/arbnn_blog_page.css'],
  },
  costToMakeaVacationRentalApp: {
    meta: {
      title: 'How Much Does it Cost to Develop an App like Airbnb?',
      description:
        'Looking to build an app like Airbnb for Android & iOS platform? Get insights into how to make an app like Airbnb & how much it will cost with WebClues.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/how-much-does-It-cost-to-make-an-app-like-airbnb.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/arbnn_blog_page.css'],
  },
  sportsBettingAppLikeDream: {
    meta: {
      title: 'How much does it cost to build fantasy sports app like Dream 11',
      description:
        'Want to know the cost to develop fantasy sports betting app like Dream11? Get complete guide on how to build fantasy sport app like Dream11 from WebClues.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/sports-betting-app-like-dream11.jpg`,
    },
    cssLinks: ['/assets/css/applikedream.css'],
  },
  sportsBettingAppBet: {
    meta: {
      title: 'How Much It Cost to Develop Sports Betting App Like Bet365?',
      description:
        'How much does it cost to develop sports betting app like Bet365? Contact us to know the complete details related to the development of apps like Bet365.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/sports-betting-app-like-bet365.jpg`,
    },
    cssLinks: ['/assets/css/bet365_page.css'],
  },
  elearningAppLikeUdemyLynda: {
    meta: {
      title: 'Cost to build an app like Udemy | eLearning App Udemy & Lynda',
      description:
        'Develop eLearning mobile apps like Udemy/Lynda with WebClues. Also, find out how much it costs to build an app like Udemy/Lynda by talking to our experts.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/app-like-udemy-lynda.jpg`,
    },
    cssLinks: ['/assets/css/udemy_blog_page.css'],
  },
  createYourOwnElearningPlatform: {
    meta: {
      title: 'Cost to build an app like Udemy | eLearning App Udemy & Lynda',
      description:
        'Develop eLearning mobile apps like Udemy/Lynda with WebClues. Also, find out how much it costs to build an app like Udemy/Lynda by talking to our experts.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/e-learning-website-and-app-like-udemy.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/udemy_blog_page.css'],
  },
  groceryDeliveryAppLikeInstacart: {
    meta: {
      title: 'How much it cost to build a grocery delivery app like Instacart?',
      description:
        'A step by step guide on building an app like Instacart. Get the estimation on the cost of developing grocery app like Instacart for both Android & iOS.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/grocery-delivery-app-like-instacart.jpg`,
    },
    cssLinks: ['/assets/css/instacart_page.css'],
  },
  socialNetworkingAppLikeInstagram: {
    meta: {
      title: 'How to create an app like Instagram and know how much it cost?',
      description:
        'Want to build an app like Instagram? Checkout its development cost, architecture plan, timeline, & tech-stack. Get detailed information.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/photo-sharing-app-like-instagram.jpg`,
    },
    cssLinks: ['/assets/css/instagram_blog_page.css'],
  },
  ecommerceWebsiteLikeAmazonEbay: {
    meta: {
      title: 'How to Make a Website Like Amazon: Tech-Stack, Cost, Feature',
      description:
        'Looking for Amazon clone script or eCommerce website development company? Hire WebClues & get best mobile app developers. Book a FREE consultation!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ecommerce-app-like-amazon.jpg`,
    },
    cssLinks: ['/assets/css/amazon_blog_page.css'],
  },
  foodDeliveryAppLikeZomato: {
    meta: {
      title: 'Cost to develop a food delivery app like Zomato?',
      description:
        'Develop a food ordering app like Zomato. Get a 360-degree overview of what, why, and how to create a food delivery app like Zomato.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/food-delivery-app-like-zomato.jpg`,
    },
    cssLinks: ['/assets/css/zomato_blog_page.css'],
  },
  taxiAppLikeUber: {
    meta: {
      title: 'How to build an app like Uber - Tech & Feature Considerations',
      description:
        "Build an app like Uber with WebClues. We are taxi booking app development company specializing in creating custom clone apps. We create taxi booking apps tailored to your business needs, matching Uber's aesthetics and features.",
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/taxi-app-like-uber.jpg`,
    },
    cssLinks: ['/assets/css/uber_blog_page.css'],
  },
  healthcareAppLikePracto: {
    meta: {
      title: 'How to make a Healthcare app|  Cost to develop app like Practo',
      description:
        'Build on-demand medical app like Practo with WebClues. Now, booking appointments, health checkups, ordering medicines online is easy. Consult today!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-practo.jpg`,
    },
    cssLinks: ['/assets/css/practo_blog_page.css'],
  },
  costToCreateaHealthCareApp: {
    meta: {
      title: 'How to make a Healthcare app|  Cost to develop app like Practo',
      description:
        'Build on-demand medical app like Practo with WebClues. Now, booking appointments, health checkups, ordering medicines online is easy. Consult today!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-practo.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/practo_blog_page.css'],
  },
  healthcareAppLikeZocdoc: {
    meta: {
      title: 'Build an app/website like Zocdoc | Features, Cost, Timeline',
      description:
        'WebClues employs unparalleled approaches in building doctor appointment booking app like ZocDoc. Get right consultation on costing, timeline, & features',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-zocdoc.jpg`,
    },
    cssLinks: ['/assets/css/app-like-zocdoc.css'],
  },
  costToMakeaMedicalApp: {
    meta: {
      title: 'Build an app/website like Zocdoc | Features, Cost, Timeline ',
      description:
        'WebClues employs unparalleled approaches in building doctor appointment booking app like ZocDoc. Get right consultation on costing, timeline, & features',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/healthcare-app-like-zocdoc.jpg`,
    },
    isNoFollow: true,
    cssLinks: ['/assets/css/app-like-zocdoc.css'],
  },
  videoStreamingAppLikeNetflix: {
    meta: {
      title: 'How to create an app like Netflix and how much does it cost?',
      description:
        'Choose the best app development company & get a complete guide on how to build an app like Netflix. Get latest video stream app features & know about cost of development.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/video-streaming-app-like-netflix.jpg`,
    },
    cssLinks: ['/assets/css/netflix_blog_page.css'],
  },
  musicVideoAppLikeTiktok: {
    meta: {
      title:
        'Complete guide on how to create an app like TikTok - WebClues Infotech',
      description:
        'Build a video-streaming app like TikTok both for Android & iOS. Know how much does it cost to build an app like TikTok. Get detailed information on building app like TikTok.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/music-app-like-tiktok.jpg`,
    },
    cssLinks: ['/assets/css/tiktok_blog_page.css'],
  },
  jewelleryWebsiteAppDevelopment: {
    meta: {
      title: 'Best Jewelry App or Website Development Company',
      description:
        'Get a custom jewelry mobile app/website for easy jewelry shopping. Hire jewelry app developers & take your jewelry business to next level. Get a free quote!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/jewellery-website-app-development.jpg`,
    },
    cssLinks: ['/assets/css/jewellery_blog_page.css'],
  },
  termsConditions: {
    meta: {
      title: 'Terms and Conditions - WebClues Infotech',
      description:
        'Our Terms and Conditions serve many useful legal purposes. It is the legal backbone of the relationship between our services and our clients.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/terms-and-conditions.jpg`,
    },
  },
  privacyPolicyPage: {
    meta: {
      title: 'Privacy Policy WebClues Infotech',
      description:
        'We at WebClues Infotech are committed to protecting the privacy of our users. We only collect the important information that we actually need to provide services to you.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/privacy-policy.jpg`,
    },
  },
  elearningAppsLikeByjus: {
    meta: {
      title: "Build eLearning app like BYJU's | Cost to build app like BYJU's",
      description:
        "WebClues can develop eLearning app like BYJU's with custom functionalities. Cost of development $8000 - $35000. Turn your idea into a profitable investment.",
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/e-learning-app-like-byjus.jpg`,
    },
    cssLinks: ['/assets/css/byjus_blog_page.css'],
  },
  videoSharingAppsLikeYoutube: {
    meta: {
      title: 'How Much Does it Cost to Build Video Sharing App like Youtube',
      description:
        'Know How Much it Cost to Build Video Sharing App like YouTube. Consult the expertise of WebClues & get all the insights on how to build an app like YouTube.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/video-sharing-app-like-youtube.jpg`,
    },
    cssLinks: ['/assets/css/youtube_blog_page.css'],
  },
  appsLikeTinder: {
    meta: {
      title: 'How much does it cost to build a dating app like Tinder?',
      description:
        'Ready to build app like Tinder! WebClues team has the right skill of developing dating apps based on an innovative feature. Get your Tinder app today!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/dating-app-like-tinder.jpg`,
    },
    cssLinks: ['/assets/css/tinder_blog_page.css'],
  },
  developChatApps: {
    meta: {
      title: 'How Much Does It Cost To Develop A Chat App',
      description:
        'WebClues - Chat App Development Company helps you build custom chat app. Know the features, technology stack & how much it costs to develop chat app.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/cost-to-develop-a-chat-app.jpg`,
    },
    cssLinks: ['/assets/css/chat_blog_page.css'],
  },
  ecommerce: {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ecommerce-app-like-amazon.jpg`,
    },
  },
  azure: {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-azure.jpg`,
    },
  },
  'dotnet-core': {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/mobile-technologies-dotnet-core.jpg`,
    },
  },
  'dotnet-mvc': {
    meta: {
      title: '',
      description: '',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/backend-technologies-dotnet.jpg`,
    },
  },
  ecommerceDevelopmentCompany: {
    meta: {
      title: 'eCommerce Development Company | Hire eCommerce Developers',
      description:
        'Trust our CMMI Level 5 certified eCommerce development company with 8+ yrs of exp & a team of 160+ experts to elevate your online business. Experience excellence in eCommerce solutions.',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/ecommerce-development-company.jpg`,
    },
  },
  generativeAIPage: {
    meta: {
      title: 'Generative AI Solutions for Your Business | WebClues Infotech',
      description:
        'At WebClues, a generative AI development company, we help businesses harness the transformative power of generative AI. Contact us today to learn more',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/emerging-tech-ai.jpg`,
    },
  },
  penetrationTestingService: {
    meta: {
      title: 'Penetration Testing Services | WebClues Infotech',
      description:
        'Trust our expert penetration testing services to uncover vulnerabilities, strengthen defenses, and safeguard your business. Connect today to learn more!',
      type: 'website',
      image: `${CDN_URL}/assets/images/og-images/penetration-testing-service.jpg`,
    },
  },
}

export default PAGE_CONSTANT_TEXTS
