// eslint-disable-next-line import/prefer-default-export
export const API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL
export const GREENHOUSE_API_URL = process.env.NEXT_PUBLIC_GREENHOUSE_API_URL
export const GREENHOUSE_API_KEY = process.env.NEXT_PUBLIC_GREENHOUSE_API_KEY
export const hubspotContactFormId =
  process.env.NEXT_PUBLIC_HUBSPOT_CONTACTUS_FORM_ID
export const hubspotSignupFormId =
  process.env.NEXT_PUBLIC_HUBSPOT_SIGNUP_FORM_ID
export const hubspotPortalId = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID
export const hubspotApiUrl = process.env.NEXT_PUBLIC_HUBSPOT_API_URL
export const googleCaptchaSecret = process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_KEY
export const gtmKey = process.env.NEXT_PUBLIC_GTM_KEY
export const hubspotNodeOperatorFormId =
  process.env.NEXT_PUBLIC_HUBSPOT_NODEOPERATOR_FORM_ID
export const hubspotPagerFormId = process.env.NEXT_PUBLIC_HUBSPOT_PAGER_FORM_ID
export const hubspotBlockchainRevolutionId =
  process.env.NEXT_PUBLIC_HUBSPOT_BLOCKCHAIN_REVOLUTION_FORM_ID
export const dataDogRumClientToken =
  process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN
export const dataDogRumApplicationId =
  process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID
export const appEnv = process.env.NEXT_PUBLIC_VERCEL_ENV
export const sitekey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
export const siteUrl = process.env.NEXT_PUBLIC_BASE_URL
export const productionCheck = process.env.NEXT_PUBLIC_PRODUCTION_CHECK
export const stagingCheck = process.env.NEXT_PUBLIC_STAGING_CHECK
export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL || '/'
