import { FC } from 'react'
import { PageDetailsInterface } from 'constants/pageMetaTags'
import HeadMetaData from 'components/HeadMetaData'

const PageWrapper: FC<PageDetailsInterface> = ({
  meta,
  cssLinks,
  isNoFollow,
  children,
}) => {
  return (
    <div>
      <HeadMetaData {...{ meta, cssLinks, isNoFollow }} />
      {children}
    </div>
  )
}

export default PageWrapper
